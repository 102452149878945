function filter01() {
    return [
        {
            fieldName: 'id',
            type: "value",
            value: 1
        }
    ]
}
export const customFilters = {
    filter01: filter01
};